import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { Global } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';
import { injectIntl, IntlContextConsumer } from 'gatsby-plugin-intl';
import Helmet from 'react-helmet';

const PrivacyPolicy = ({ data, intl }) => {
  return (<>
    <Global
      styles={theme => ({
        body: {
          fontFamily: theme.fonts.body,
        }
      })}
    />
    <Layout>
      <SEO title={ intl.formatMessage({ id: 'privacy' })}/>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <Helmet>
            <link rel="alternate" hrefLang="x-default" href="https://couponise.app/privacy-policy/" />
            {languages.map(language => {
              if(language !== intl.defaultLocale) {
                return (
                  <link key={language} rel="alternate" hrefLang={`${language}`} href={`https://couponise.app/${language}/privacy-policy/`} />
                )
              }
            })}
          </Helmet>
        )}
      </IntlContextConsumer>
      <div className="container mt-30">
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </div>
    </Layout>
  </>);
};

export default injectIntl(PrivacyPolicy);

export const pageQuery = graphql`
  query($path: String) {
    markdownRemark(frontmatter: {slug: {eq: $path}}) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`
